@import "../../App.scss";

.batchAddCustomers,
batchAddVendors {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input {
    padding: 5px;
    font-size: 1rem;
    border: 1px solid lightgray;
  }

  p {
    margin: 10px 0;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin-left: 15px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  h2 {
    font-size: 1.4rem;
    margin: 15px 0;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin: 0 0 30px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    max-width: 200px;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  label {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  input,
  select {
    padding: 5px;
    font-size: 1rem;
    max-width: 300px;
    border: 1px solid lightgray;
    margin-bottom: 20px;
  }

  option {
    font-size: 1rem;
  }

  h3 {
    margin: 30px 0;
    padding-top: 30px;
    border-top: 5px solid crimson;
    font-size: 2rem;
    color: crimson;
  }
}

.errors {
  background: crimson;
  padding: 20px;
  border-radius: 10px;

  li {
    font-size: 1.1rem;
    margin: 10px 0;
  }
}

button.disabled {
  background: lightgray;

  &:hover {
    background: lightgray;
    cursor: not-allowed;
  }
}
