@import "../../App.scss";

.editItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input {
    padding: 5px;
    font-size: 1rem;
    width: 190px;
    border: 1px solid lightgray;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin-left: 15px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }
}

.itemList {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
  border-top: 1px solid lightgray;

  .product {
    max-width: 175px;
    padding: 15px;
    margin: 15px;
    border-radius: 10px;

    &:hover {
      box-shadow: 0 0 10px 1px lightgray;
      cursor: pointer;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: black;
    }

    img {
      max-height: 175px;
      max-width: 150px;
    }

    h3 {
      display: block;
      margin: 10px 0 5px;
      font-size: 1.2rem;
      font-weight: bold;
      max-width: 150px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      font-size: 1rem;
      color: gray;
    }

    span {
      margin-top: 10px;
      padding: 10px 7px;
      background: $mainColor;
      border-radius: 7px;

      &:hover {
        background: darken($mainColor, 5%);
      }
    }
  }
}

.editItemForm {
  h2 {
    font-size: 1.4rem;
    margin: 15px 0;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin: 0 0 30px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    max-width: 200px;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  label {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  input,
  select {
    padding: 5px;
    font-size: 1rem;
    max-width: 300px;
    border: 1px solid lightgray;
    margin-bottom: 20px;
  }

  option {
    font-size: 1rem;
  }

  h3 {
    margin: 30px 0;
    padding-top: 30px;
    border-top: 5px solid crimson;
    font-size: 2rem;
    color: crimson;
  }
}

.errors {
  background: crimson;
  padding: 20px;
  border-radius: 10px;

  li {
    font-size: 1.1rem;
    margin: 10px 0;
  }
}

button.disabled {
  background: lightgray;

  &:hover {
    background: lightgray;
    cursor: not-allowed;
  }
}

input.typeahead-input {
    margin-bottom: 0;
}

.typeahead::before {
    content: '';
}

.typeahead {
    position: absolute;
    z-index: 3;
    background: white;
    margin-top: 267px;
    border: 1px solid gray;
}

.typeahead li {
    padding: 10px 5px;
    cursor: pointer;
}

.typeahead li:hover {
    background: lightgray;
    text-decoration: underline;
}

label[for="vendorItemNumber"] {
    margin-top: 25px;
}