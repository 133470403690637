.featuredCategories {
  max-width: 90%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    width: 100%;
    text-align: left;
    font-size: 1.4rem;
  }

  .featuredCategories-container {
    width: 100%;
    display: flex;
    overflow: auto;
    margin: 5px 0;
    padding: 15px;
    border: 1px solid lightgray;
    border-top: 3px solid lightgray;
  }

  .featuredCategories-group {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    border-right: 1px solid lightgray;

    &:last-child {
      border-right: 0;
    }
  }

  a {
    text-decoration: none;
    font-size: 1.2rem;
    color: black;
    margin: 5px 0;

    &:hover {
      text-decoration: underline;
    }
  }
}
