@import "../../App.scss";

.searchBar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  form{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }

  input {
    padding: 5px;
    font-size: 1rem;
    width: 80%;
    border: 1px solid lightgray;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin-left: 15px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  h1 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}
