.productDetailPage {
  display: flex;
  flex-direction: column;
  padding: 30px;

  img {
    max-width: 300px;
    max-height: 300px;
  }

  div {
    display: flex;
    margin-left: 30px;

    @media (max-width: 670px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
  }

  .productDetailPage-textContent {
    margin-left: 30px;
    display: block;
  }

  h3 {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 10px 0;
  }

  p {
    font-size: 1rem;
    font-style: italic;
    color: gray;
    margin-bottom: 30px;
  }

  a {
    color: gray;
  }

  span {
    margin-bottom: 30px;
  }

  .productImage{
      display: flex;
      flex-direction: column;

      p{
          margin-top: 10px;
      }
  }
}
