@import "../App.scss";

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: auto;

  .carousel-products {
    display: flex;
    width: 100%;
    align-items: center;
  }

  button {
    font-size: 1rem;
    padding: 9px 10px 10px;
    border-radius: 100%;
    border: 0;
    background: $mainColor;
    cursor: pointer;
    outline: none;
    max-height: 40px;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  .hidden {
    visibility: hidden;
  }
}
