.homePage {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .homePage-featuredProducts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px;
    border: 3px solid gray;
    border-radius: 3px;
    width: 100%;
    max-width: 900px;

    h2 {
      width: 100%;
      padding: 10px 10px 12px;
      font-size: 1.4rem;
      background: linear-gradient(to top left, gray, darken(gray, 10%));
      color: white;
    }
  }
}
