@import "../App.scss";

.loginPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    margin: 20px 0;

    h2 {
      font-size: 1.2rem;
    }

    p {
      font-size: 1rem;
      margin: 5px 0;
    }

    a {
      font-size: 1rem;
      color: gray;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      padding: 5px;
      font-size: 1rem;
      max-width: 300px;
      border: 1px solid lightgray;
    }

    label {
      font-size: 1rem;
      color: gray;
      margin: 10px;
    }

    button {
      background: $mainColor;
      border: 0px;
      padding: 7px 15px;
      margin: 15px 0;
      font-size: 1rem;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: darken($mainColor, 5%);
      }
    }
  }
}
