@import "../../App.scss";

@keyframes glow {
  from {
    box-shadow: 0 0 5px #fff, 0 0 7px darken($mainColor, 10%);
  }

  to {
    box-shadow: 0 0 7px #fff, 0 0 10px black;
  }
}

.accessButtons {
  display: flex;
  align-items: center;

  a {
    background: $mainColor;
    border: 0px;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    margin: 0 15px;
    color: black;
    text-decoration: none;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  h2 {
    font-size: 1.2rem;
    margin-right: 10px;
    padding-right: 20px;
    border-right: 1px solid lightgray;
    text-align: right;

    @media (max-width: 900px) {
      display: none;
    }
  }
}

.items {
  animation: glow 1s ease-in-out infinite alternate;
}

a.inactive {
  cursor: not-allowed;
  background: gray;

  &:hover {
    background: gray;
  }
}
