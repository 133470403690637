@import "../../App.scss";

.contacts,
.addVendors {
  h2 {
    font-size: 1.4rem;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      font-size: 1rem;
      margin-bottom: 5px;
    }

    input,
    select {
      padding: 5px;
      font-size: 1rem;
      max-width: 300px;
      border: 1px solid lightgray;
      margin-bottom: 20px;
    }

    option {
      font-size: 1rem;
    }
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    color: black;
    text-decoration: none;
    max-width: 150px;

    &:hover {
      background: darken($mainColor, 5%);
      cursor: pointer;
    }
  }

  button.disabled {
    background: gray;
    cursor: not-allowed;
  }

  .tableContainer {
    overflow: auto;
    margin-bottom: 20px;
  }

  table {
    width: 930px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 30px;
  }

  thead {
    tr {
      border-bottom: 1px solid lightgray;
    }
  }

  h2 {
    font-size: 1.4rem;
    padding: 0 0 20px 20px;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

    @media (max-width: 670px) {
      padding: 15px 10px;
    }

    &:nth-child(even) {
      background: lighten(lightgray, 5%);
    }
  }

  td,
  th {
    width: 14%;
    text-align: center;
    padding: 0 10px;
  }

  td {
    word-break: break-all;
  }

  th {
    font-weight: bold;
  }

  input {
    font-size: 1rem;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    max-width: 100%;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    color: black;
    text-decoration: none;

    &:hover {
      background: darken($mainColor, 5%);
      cursor: pointer;
    }
  }
}
