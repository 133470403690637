.searchTermPage {
  padding: 20px;

  span,
  a {
    color: gray;
  }

  h2 {
    font-size: 1.2rem;
    padding: 20px;
    margin-top: 20px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }

  .productList {
    display: flex;
    flex-flow: row wrap;

    a {
      color: black;
    }
  }

  .productListView {
    a {
      align-items: flex-start;
    }
  }
}

.noResults {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 30px;
}
