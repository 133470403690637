.accountPage {
  display: flex;
  padding: 30px;

  @media (max-width: 670px) {
    flex-direction: column;
  }
}

.accountPage-left {
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid lightgray;
  box-shadow: 8px 0 10px -6px lightgray;

  @media (max-width: 670px) {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
    border-bottom: 1px solid lightgray;
    margin-bottom: 30px;
    box-shadow: 0 8px 10px -6px lightgray;
  }

  ul {
    list-style: circle;
    list-style-position: inside;
    min-width: 120px;

    @media (max-width: 670px) {
      display: flex;
      list-style: none;
      justify-content: space-between;
    }
  }

  li {
    font-size: 1rem;
    font-weight: bold;
    margin: 20px 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.accountPage-right {
  width: 75%;

  @media (max-width: 670px) {
    width: 100%;
  }
}
