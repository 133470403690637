.favoritesList {
  padding: 30px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    padding: 30px 0;
  }

  img {
    width: 50px;
    cursor: pointer;
  }

  table,
  thead,
  tbody,
  tr {
    overflow: auto;
  }

  thead {
    tr {
      border-bottom: 1px solid lightgray;
      border-top: 1px solid lightgray;
    }
  }

  h2 {
    font-size: 1.4rem;
    padding: 0 0 20px 20px;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

    @media (max-width: 670px) {
      padding: 15px 10px;
    }

    &:nth-child(even) {
      background: lighten(lightgray, 5%);
    }
  }

  td,
  th {
    width: 35%;
    text-align: center;
    padding: 0 10px;
  }

  td {
    overflow-wrap: break-word;
  }

  th {
    font-weight: bold;
  }
}
