.footer {
  background: #ddd;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 0 15px 2px gray;
  display: flex;
  justify-content: space-between;

  @media (max-width: 670px) {
    flex-direction: column;
    align-items: center;
  }

  div {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  h2 {
    font-size: 1.4rem;
  }

  p,
  a {
    font-size: 1rem;
    font-style: italic;
    padding: 5px 0;
  }

  a {
    color: black;
    text-decoration: none;
  }

  span {
    font-weight: bold;
    font-style: initial;
  }

  img {
    margin-left: 10px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
  }
}

.footer * {
    background: #ddd;
}