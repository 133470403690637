@import "../../App.scss";

.adminOrderHistory {
  font-size: 1rem;

  h2 {
    font-size: 1.4rem;
  }

  p {
    margin: 15px 0;
  }

  label {
    display: none;
  }

  input {
    padding: 5px;
    font-size: 1rem;
    max-width: 300px;
    border: 1px solid lightgray;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin-left: 15px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  .tableContainer {
    overflow: auto;
  }

  table {
    width: 600px;
    border-bottom: 1px solid lightgray;
    margin-top: 30px;
  }

  thead {
    tr {
      border-bottom: 1px solid lightgray;
    }
  }

  h2 {
    font-size: 1.4rem;
    padding: 0 0 20px 20px;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

    @media (max-width: 670px) {
      padding: 15px 10px;
    }

    &:nth-child(even) {
      background: lighten(lightgray, 5%);
    }

    &:hover {
      cursor: pointer;
      background: darken(lightgray, 10%);
    }
  }

  td,
  th {
    width: 14%;
    text-align: center;
    padding: 0 10px;
  }

  td {
    word-break: keep-all;
  }

  th {
    font-weight: bold;
  }
}
