.errorMessage {
  background: #ff3333;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;

  p {
    margin: 5px 0;
  }

  a {
    color: black;
  }
}
