@import "../App.scss";

.productDetailPricing {
  border-top: 1px solid lightgray;
  padding: 20px 0;

  li {
    font-size: 1rem;
    margin: 10px 0;
  }
}

.productDetailPricing div.cartControls {
  margin-left: 0;

  button {
    background: $mainColor;
    border: 0px;
    padding: 5px 10px;
    font-size: 1rem;
    border-radius: 5px;
    color: black;
    text-decoration: none;

    &:hover {
      background: darken($mainColor, 5%);
      cursor: pointer;
    }
  }

  input {
    padding: 5px;
    font-size: 1rem;
    max-width: 100px;
    border: 1px solid lightgray;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
