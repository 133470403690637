@import "../App.scss";

h1 {
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}
.checkoutForm {
  h2 {
    font-size: 1.4rem;
    margin: 15px 0;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    padding: 0 50px;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin: 0 0 30px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    max-width: 200px;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  label {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  input,
  select {
    padding: 5px;
    font-size: 1rem;
    max-width: 300px;
    border: 1px solid lightgray;
    margin-bottom: 20px;
  }

  option {
    font-size: 1rem;
  }

  img {
    width: 50px;
    cursor: pointer;
  }

  table,
  thead,
  tbody,
  tr {
    overflow: auto;
  }

  thead {
    tr {
      border-bottom: 1px solid lightgray;
      border-top: 1px solid lightgray;
    }
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

    @media (max-width: 670px) {
      padding: 15px 10px;
    }

    &:nth-child(even) {
      background: lighten(lightgray, 5%);
    }
  }

  td,
  th {
    width: 14%;
    text-align: center;
    padding: 0 10px;
  }

  td {
    overflow-wrap: break-word;
  }

  th {
    font-weight: bold;
  }
}
.shoppingCartContents {
  margin: 0 auto;
  width: 80%;
}

.invisible {
  visibility: hidden;
}

.paymentInfo {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    padding: 0 50px;
}

.checkoutForm .AcceptUI {
    margin: 30px;
}