.charityBanner {
    width: 80%;

    a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 825px) {
            flex-direction: column;
        }
    }

    img {
        width: 300px;
    }
}
