@import "../App.scss";

.favorite {
  margin-bottom: 30px;

  img {
    width: 30px;
    margin-left: 20px;
  }
}

section.favorite > button {
  background: $mainColor;
  border: 0px;
  padding: 5px 10px;
  font-size: 1rem;
  border-radius: 5px;
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    background: darken($mainColor, 5%);
    cursor: pointer;
  }
}

.favorite-icon {
  filter: invert(100%);
}
