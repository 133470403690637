@import "../App.scss";

.orderPage {
  padding: 30px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    padding: 30px 0;
  }

  div {
    padding: 0 15px;
    max-width: 600px;

    p {
      line-height: 1.2rem;
      margin: 10px 0;
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h1 {
    font-size: 1.4rem;
    margin-top: 20px;
  }

  h3 {
    font-size: 1.2rem;
    margin: 10px 0 20px;
  }

  img {
    width: 50px;
    cursor: pointer;
  }

  table,
  thead,
  tbody,
  tr {
    overflow: auto;
  }

  thead {
    tr {
      border-bottom: 1px solid lightgray;
      border-top: 1px solid lightgray;
    }
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

    @media (max-width: 670px) {
      padding: 15px 10px;
    }

    &:nth-child(even) {
      background: lighten(lightgray, 5%);
    }
  }

  td,
  th {
    width: 14%;
    text-align: center;
    padding: 0 10px;
  }

  td {
    overflow-wrap: break-word;
  }

  th {
    font-weight: bold;
  }

  button {
    margin-top: 50px;
    max-width: 100px;
    background: $mainColor;
    border: 0px;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    color: black;
    text-decoration: none;

    &:hover {
      background: darken($mainColor, 5%);
      cursor: pointer;
    }
  }
}

.cartTotal {
  margin: 30px 30px 0;

  h3 {
    font-size: 1.2rem;
    border-bottom: 1px solid lightgray;
    padding-bottom: 30px;
  }

  button {
    margin-top: 15px;
  }
}
