@import "../../App.scss";

.editUsers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input {
    padding: 5px;
    font-size: 1rem;
    width: 190px;
    border: 1px solid lightgray;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin-left: 15px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }
}

.financeInfoRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 500px;
}

.financeInfoColumn {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.editUserForm {
  h2 {
    font-size: 1.4rem;
    margin: 15px 0;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin: 0 0 30px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    max-width: 200px;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }
}

.usersList {
  width: 500px;
  padding: 30px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    padding: 30px 0;
  }

  h2 {
    font-size: 1.4rem;
    padding: 0 0 20px 20px;
  }

  input {
    font-size: 1rem;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    max-width: 100%;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    color: black;
    text-decoration: none;

    &:hover {
      background: darken($mainColor, 5%);
      cursor: pointer;
    }
  }

  table {
    border-bottom: 1px solid lightgray;
    margin-top: 30px;
  }

  thead {
    tr {
      border-bottom: 1px solid lightgray;
    }
  }

  h2 {
    font-size: 1.4rem;
    padding: 0 0 20px 20px;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

    @media (max-width: 670px) {
      padding: 15px 10px;
    }

    &:nth-child(even) {
      background: lighten(lightgray, 5%);
    }
  }

  tbody tr {
    &:hover {
      cursor: pointer;
      background: darken(lightgray, 10%);
    }
  }

  td,
  th {
    width: 40%;
    text-align: center;
    padding: 0 10px;
  }

  td {
    word-break: keep-all;
  }

  th {
    font-weight: bold;
  }
}

.editUsersForm {
  h2 {
    font-size: 1.4rem;
    margin: 15px 0;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin: 0 0 30px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    max-width: 200px;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  label {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  input,
  select {
    padding: 5px;
    font-size: 1rem;
    max-width: 300px;
    border: 1px solid lightgray;
    margin-bottom: 20px;
  }

  option {
    font-size: 1rem;
  }

  h3 {
    margin: 30px 0;
    padding-top: 30px;
    border-top: 5px solid crimson;
    font-size: 2rem;
    color: crimson;
  }
}
