@import "../App.scss";

.productListView {
  max-width: 175px;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;

  &:hover {
    box-shadow: 0 0 10px 1px lightgray;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
  }

  img {
    max-height: 175px;
    max-width: 150px;
  }

  h3 {
    display: block;
    margin: 10px 0 5px;
    font-size: 1.2rem;
    font-weight: bold;
    max-width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 1rem;
    color: gray;
  }

  h4 {
    margin-top: 10px;
  }

  span {
    margin-top: 10px;
    padding: 10px 7px;
    background: $mainColor;
    border-radius: 7px;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }
}
