@import "../../App.scss";

.manageCategories {
  h2 {
    font-size: 1.4rem;
    margin: 15px 0;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }
  input {
    padding: 5px;
    font-size: 1rem;
    width: 190px;
    border: 1px solid lightgray;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin-left: 15px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }

  form {
    label {
      margin: 10px 0;
      &:nth-child(4) {
        margin-top: 20px;
      }
    }
    display: flex;
    flex-direction: column;
    margin: 30px 0;

    button {
      margin-top: 20px;
      max-width: 150px;
    }
  }

  table {
    border-bottom: 1px solid lightgray;
    margin-top: 30px;
  }

  thead {
    tr {
      border-bottom: 1px solid lightgray;
    }
  }

  h2 {
    font-size: 1.4rem;
    padding: 0 0 20px 20px;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

    @media (max-width: 670px) {
      padding: 15px 10px;
    }

    &:nth-child(even) {
      background: lighten(lightgray, 5%);
    }
  }

  td,
  th {
    width: 40%;
    text-align: center;
    padding: 0 10px;
  }

  td {
    word-break: keep-all;
  }

  th {
    font-weight: bold;
  }
}
