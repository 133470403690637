.logo {
  margin: 0 50px 0 20px;

  img {
    width: 165px;
  }

  @media (max-width: 900px) {
    margin: 10px 0;

    img {
      width: 200px;
    }
  }
}
