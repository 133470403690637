@import "../App.scss";

.registration {
  padding: 30px;
}

.registrationStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;

  option {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.4rem;
    padding: 0 0 15px 15px;
    border-bottom: 1px solid gray;
    width: 100%;
  }

  p {
    margin: 10px 0;
    max-width: 500px;
    line-height: 1.4rem;
  }

  form {
    padding: 30px;
    border: 1px solid lightgray;
    margin: 30px 0;
    box-shadow: 0 0 15px 5px lightgray;
    border-radius: 10px;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    padding: 15px;
  }

  label {
    font-size: 1rem;
    margin: 5px 0;
    color: gray;
  }

  input,
  select {
    padding: 5px;
    font-size: 1rem;
    max-width: 300px;
    border: 1px solid lightgray;
    margin: 10px 0;
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 7px 15px;
    margin-left: 15px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: darken($mainColor, 5%);
    }
  }
}

.addressBlockSection {
  input,
  select {
    margin-left: 15px;
  }
}

.submitButton.inactive {
  background: gray;
  cursor: not-allowed;

  &:hover {
    background: gray;
  }
}

.error {
  background: #ff3333;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
}
