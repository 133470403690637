@import "../../App.scss";

.header {
  display: flex;
  width: 100%;
  padding: 10px 5px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .header-left {
    display: flex;
    max-width: 75%;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      margin-bottom: 30px;
    }
  }
}
