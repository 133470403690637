@import "../../App.scss";

.account {
  h2 {
    font-size: 1.4rem;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
  }

  .customerInfo {
    display: flex;
    flex-direction: column;

    label {
      font-size: 1rem;
      margin-bottom: 5px;
    }

    input,
    select {
      padding: 5px;
      font-size: 1rem;
      max-width: 300px;
      border: 1px solid lightgray;
      margin-bottom: 20px;
    }

    option {
      font-size: 1rem;
    }
  }

  button {
    background: $mainColor;
    border: 0px;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    color: black;
    text-decoration: none;
    max-width: 150px;

    &:hover {
      background: darken($mainColor, 5%);
      cursor: pointer;
    }
  }

  button.disabled {
    background: gray;
    cursor: not-allowed;
  }
}
