.navigation {
  margin: 10px 0;
  box-shadow: 0 0 15px 5px lightgray;
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  a,
  #categories {
    text-decoration: none;
    color: black;
    padding: 0 10px;
    cursor: pointer;
  }

  #categories {
    @media (max-width: 900px) {
      border-right: 0px;
    }
  }

  a[href="/onsale"] {
    @media (max-width: 900px) {
      border: 0px;
    }
  }
}

.categoriesModal {
  display: inline-flex;
  padding: 20px;
  background: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 0 0 5px 1px lightgray;

  div {
    padding: 0 15px;
    border-right: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    font-size: 1.2rem;
  }

  a {
    font-size: 1rem;
    margin: 5px;
    max-height: 20px;
    text-decoration: none;
    color: gray;

    &:hover {
      text-decoration: underline;
    }
  }
}

section.categoriesModal > a {
  margin-left: 15px;
}

.modal {
  display: none;
  position: absolute;
  z-index: 10;
  top: 150px;

  @media (max-width: 900px) {
    display: none;
    top: 65px;
  }
}

#categories:hover .modal {
  display: block;
}

span.hidden {
  display: none;
}

.navigation-right {
  @media (max-width: 900px) {
    display: none;
  }
}

.navigation-left {
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 15px;
    padding-bottom: 10px;

    a {
      padding: 15px;
      border-top: 1px solid lightgray;
      border-right: 0;
    }
  }
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 1.7rem;
  margin-left: 20px;

  @media (max-width: 900px) {
    display: block;
  }
}

.mobile-show {
  @media (max-width: 900px) {
    display: flex;
  }
}

.mobile-hide {
  @media (max-width: 900px) {
    display: none;
  }
}

#scroll {
    position: fixed;
    bottom: 0px;
    right: 0px;
    font-size: 1.2rem;
    font-weight: bold;
    background: #f6bf1f;
    border-top-left-radius: 15px;
    padding: 15px;
    cursor: pointer;
}