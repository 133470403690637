.allCategories {
  padding: 20px;

  h2 {
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
  }

  .allCategories-catContent {
    display: flex;
    flex-flow: row wrap;
  }

  div {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;

    h3 {
      font-size: 1.4rem;
      font-weight: bold;
      padding-bottom: 3px;
      border-bottom: 1px solid lightgray;
    }

    a {
      font-size: 1rem;
      text-decoration: none;
      color: gray;
      padding: 5px 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
